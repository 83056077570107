var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"text-center"},[_c('v-dialog',{attrs:{"width":"600","content-class":_vm.$vuetify.breakpoint.xs ? '' : 'rounded-xl',"fullscreen":_vm.$vuetify.breakpoint.xs,"persistent":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var dialog = ref.on;
return [_c('v-tooltip',{attrs:{"transition":"fade-transition","top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
return [(_vm.showBtn)?_c('v-btn',_vm._g(_vm._b({attrs:{"color":_vm.btnProps.color || 'success',"text":""}},'v-btn',_vm.btnProps,false),Object.assign({}, dialog, tooltip)),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-alarm-plus ")]),(!_vm.external)?[_vm._v(" Add hours ")]:_vm._e()],2):_vm._e()]}}],null,true)},[_c('span',[_vm._v("Add Hours")])])]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5 primary white--text"},[_vm._v(" Add Overtime to: "+_vm._s(_vm.employee.fullname)+" ")]),_c('v-card-text',[_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"col-sm-6"},[_c('v-text-field',{attrs:{"outlined":"","type":"number","rules":[_vm.rules.required],"dense":"","label":"Hours"},on:{"click":_vm.clickHours},model:{value:(_vm.hours),callback:function ($$v) {_vm.hours=$$v},expression:"hours"}}),(_vm.noValidHour)?[_c('span',{staticClass:"red--text"},[_vm._v(" "+_vm._s("The time must be between " + 0 + " and " + 24)+" ")])]:_vm._e()],2),_c('div',{staticClass:"col-sm-6"},[_c('v-menu',{ref:"menu",attrs:{"close-on-content-click":false,"return-value":_vm.dates,"transition":"scale-transition","offset-y":"","min-width":"auto"},on:{"update:returnValue":function($event){_vm.dates=$event},"update:return-value":function($event){_vm.dates=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-combobox',_vm._g(_vm._b({attrs:{"multiple":"","chips":"","disabled":_vm.external,"small-chips":"","label":"Days","prepend-icon":"mdi-calendar","readonly":""},model:{value:(_vm.dates),callback:function ($$v) {_vm.dates=$$v},expression:"dates"}},'v-combobox',attrs,false),on))]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-date-picker',{attrs:{"no-title":"","multiple":"","scrollable":""},model:{value:(_vm.dates),callback:function ($$v) {_vm.dates=$$v},expression:"dates"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.menu = false}}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.$refs.menu.save(_vm.dates)}}},[_vm._v(" OK ")])],1)],1)],1)]),[_c('div',{staticClass:"d-flex justify-end"},[_c('v-btn',{attrs:{"disabled":_vm._inValidForm,"color":"primary"},on:{"click":_vm.addExtras}},[_c('v-icon',[_vm._v("mdi-plus")]),_vm._v(" Add")],1)],1)],(_vm.req.extras.length != 0)?[_c('v-divider'),_c('div',{staticClass:"d-flex"},[_c('v-simple-table',{scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',{staticClass:"text-left"},[_vm._v("Day")]),_c('th',{staticClass:"text-left"},[_vm._v("Hours")])])]),_c('tbody',_vm._l((_vm.req.extras),function(h,index){return _c('tr',{key:index},[_c('td',[_vm._v(_vm._s(_vm.prettyDay(h.day)))]),_c('td',[_vm._v(_vm._s(h.hours + "H"))])])}),0)]},proxy:true}],null,false,4114733858)})],1)]:_vm._e()],2),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"error","text":""},on:{"click":_vm.cancel}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"disabled":_vm.invalidAdd,"color":"primary","text":""},on:{"click":_vm.addovertime}},[_vm._v(" Confirm ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
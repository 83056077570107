<template>
  <v-dialog v-model="dialogPin" width="400" min-width="400" min-height="110" persistent>
    <template v-slot:activator="{ on: dialog }">
      <v-tooltip transition="fade-transition" top>
        <template v-slot:activator="{ on: tooltip }">
          <v-btn :color="'success'" class="ml-2" text small depressed v-on="{ ...dialog, ...tooltip }" v-bind="btnProps"
            @click="generate()">
            <v-icon small> mdi-barcode </v-icon>
            <template v-if="!external"> Gen Pin </template>
            <template v-else> Pin </template>
          </v-btn>
        </template>

        <span>Generate Pin</span>
      </v-tooltip>
    </template>
    <v-card min-width="400" min-height="110">
      <template v-if="loading">
        <div class="d-flex justify-center ma-4">
          <v-progress-circular :size="50" color="primary" indeterminate></v-progress-circular>
        </div>
      </template>
      <template v-else>
        <v-toolbar dark color="primary"> Pin </v-toolbar>
        <v-card-text class="mt-1">
          Name:
          <h2>
            {{ itemTempPin != null ? itemTempPin.employee : "" }}
          </h2>
          Pin:
          <h2>
            {{ itemTempPin != null ? itemTempPin.pin : "" }}
          </h2>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="dialogPin = false" text color="error">Closed</v-btn>
        </v-card-actions>
      </template>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapActions, mapState } from "vuex";
export default {
  props: {
    external: {
      type: Boolean,
      default: () => false,
    },
    uuid: String,
    btnProps: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      itemTempPin: null,
      dialogPin: false,
    };
  },
  computed: {
    ...mapState("crmEmployeeModule", ["loading"]),
  },
  methods: {
    ...mapActions("crmEmployeeModule", ["actGeneratePin"]),
    async generate() {
      const res = await this.actGeneratePin(this.uuid);
      this.itemTempPin = res;
      this.$emit("pin", res.pin);
    },
  },
};
</script>
<style lang=""></style>

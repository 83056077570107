<template>
  <div class="text-center">
    <v-dialog v-model="dialog" width="600" :content-class="$vuetify.breakpoint.xs ? '' : 'rounded-xl'"
      :fullscreen="$vuetify.breakpoint.xs" persistent>
      <template v-slot:activator="{ on: dialog }">
        <v-tooltip transition="fade-transition" top>
          <template v-slot:activator="{ on: tooltip }">
            <v-btn :color="btnProps.color || 'success'" text v-if="showBtn" v-on="{ ...dialog, ...tooltip }"
              v-bind="btnProps">
              <v-icon small> mdi-alarm-plus </v-icon>
              <template v-if="!external"> Add hours </template>
            </v-btn>
          </template>

          <span>Add Hours</span>
        </v-tooltip>
      </template>

      <v-card>
        <v-card-title class="text-h5 primary white--text">
          Add Overtime to: {{ employee.fullname }}
        </v-card-title>

        <v-card-text>
          <div class="d-flex">
            <div class="col-sm-6">
              <v-text-field outlined v-model="hours" type="number" :rules="[rules.required]" @click="clickHours" dense
                label="Hours"></v-text-field>

              <template v-if="noValidHour">
                <span class="red--text">
                  {{ "The time must be between " + 0 + " and " + 24 }}
                </span>
              </template>
            </div>
            <div class="col-sm-6">
              <v-menu ref="menu" v-model="menu" :close-on-content-click="false" :return-value.sync="dates"
                transition="scale-transition" offset-y min-width="auto">
                <template v-slot:activator="{ on, attrs }">
                  <v-combobox v-model="dates" multiple chips :disabled="external" small-chips label="Days"
                    prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"></v-combobox>
                </template>
                <v-date-picker v-model="dates" no-title multiple scrollable>
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="menu = false">
                    Cancel
                  </v-btn>
                  <v-btn text color="primary" @click="$refs.menu.save(dates)">
                    OK
                  </v-btn>
                </v-date-picker>
              </v-menu>
            </div>
          </div>
          <template>
            <div class="d-flex justify-end">
              <v-btn @click="addExtras" :disabled="_inValidForm" color="primary"><v-icon>mdi-plus</v-icon> Add</v-btn>
            </div>
          </template>

          <template v-if="req.extras.length != 0">
            <v-divider></v-divider>
            <div class="d-flex">
              <v-simple-table>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left">Day</th>
                      <th class="text-left">Hours</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(h, index) in req.extras" :key="index">
                      <td>{{ prettyDay(h.day) }}</td>
                      <td>{{ h.hours + "H" }}</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </div>
          </template>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" text @click="cancel"> Cancel </v-btn>
          <v-btn :disabled="invalidAdd" color="primary" text @click="addovertime">
            Confirm
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import moment from "moment";
import { notifyError, notifySuccess } from "@/components/Notification";
import rules from "@/components/account/rules";
import { ResponseStatus } from "@/models/ResponseStatusEnum";
import { mapActions } from "vuex";
export default {
  props: {
    employee: Object,
    day: String,
    btnProps: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      dialog: false,
      menu: false,
      rules,
      hours: 0,
      dates: [],
      req: {
        employeeUuid: "",
        extras: [],
      },
    };
  },

  computed: {
    showBtn() {
      if (this.day == undefined) {
        return true;
      }
      const today = moment().hour(0).minute(0).second(0);
      const day = moment(this.day).hour(23).minute(59).second(59);
      if (today > day) {
        return false;
      }

      return true;
    },

    invalidAdd() {
      if (this.req.employeeUuid == "" || this.req.extras == []) {
        return true;
      }
      return false;
    },

    noValidHour() {
      if (Number(this.hours) < 0 || Number(this.hours) > 24) {
        return true;
      }
      return false;
    },
    external() {
      if (this.day != undefined) {
        return true;
      }
      return false;
    },

    _inValidForm() {
      if (this.hours == 0 || this.dates.length == 0) {
        return true;
      }
      return false;
    },
  },
  watch: {
    dialog(val) {
      if (val == true && this.day != undefined) {
        this.dates.push(this.day);
      }
    },
  },
  methods: {
    ...mapActions("crmConfigurationsModule", ["actAddOvertime"]),
    addExtras() {
      this.dates.forEach((d) => {
        this.req.extras.push({
          day: moment(d).toISOString(),
          hours: Number(this.hours),
        });
      });
      this.dates = [];
      this.hours = 0;
    },

    prettyDay(date) {
      if (date === null || date === "") return "";

      const fecha = moment(date).format("dddd D MMMM YYYY, h:mm:ss a");
      const day = fecha.split(" ")[0] + " " + fecha.split(" ")[1];

      return day;
    },

    addovertime: function () {
      this.req.employeeUuid = this.employee.uuid;
      this.actAddOvertime(this.req)
        .then(() => {
          this.$emit("overtimeadded");
          notifySuccess("OverTime added  successfully");
          this.cancel();
        })
        .catch((error) => {
          let mess = error.response.data.message;

          if (mess.includes("[")) {
            mess = mess.replace("[", "");
            mess = mess.replace("]", "");
          }
          notifyError(error.response.data, `An error occurred: ${mess}`);
          this.cancel();
        });
    },

    cancel() {
      this.hours = 0;
      this.dates = [];
      this.menu = false;
      this.dialog = false;
      this.req = { employeeUuid: "", extras: [] };
    },

    clickHours() {
      if (this.hours == 0) {
        this.hours = "";
      }
    },
  },
};
</script>
